import $ from 'jquery'
import {hideIMTableLoading, showIMTableLoading} from "../supportFunctions/consts";
import {createInventoryManagementTabulator} from "./inventoryManagementTable";

export function getInventoryManagementList(parentid) {
    showIMTableLoading()
    let data = {
        "parent_id": parentid[0]['id'],
    }
    let settings = {
        "url": process.env.appurl + "/api/getInventoryManagementData",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": data
    }
    $.ajax(settings).done(function (response) {
        createInventoryManagementTabulator(response)
        hideIMTableLoading()
    })
}

// export function getCols() {
//     let columnobjs = []
//     $.each(inventoryManagementTabulator['columnManager']['columns'], function (index, value) {
//         columnobjs.push(value['definition'])
//     })
// }