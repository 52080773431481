import $ from 'jquery'

export const info = $('#infocontainer');
export const coordinateDisplayDiv = $('#coordinateDisplayDiv')
export const nextbutton = $("#nextbutton");
export const prevbutton = $("#prevbutton");
export const assignbutton = $("#assignmentsbutton");
export const otherinfo = $("#otherinfo");
export const _blank = "_blank";
export const submaprow = $('#submaprow');
export const currentpanelinfobox = $("#currentpanelid");
export const currentpanelstuff = $("#currentpanelstuff");
export const currentpanelwkt = $("#currentPanelWKT");
export const currentpanelgeojson = $("#panelInfoDropDownText");
export const searchbuttons = $("#searchbuttons")
export const searchfield = $("#searchfield")
export const assignmentTable = $("#assignmentTable")
export let assignmentHeaderText = $("#assignmentHeaderText")
export const assignmentManagementButtons = $("#assignmentMangementButtons")
export const subMapLoading = $("#submapLoading")
export const infoLoading = $("#infoLoading")
export const mapUtilsBody = $("#mapUtilsBody")
export const mapUtilsHeader = $("#mapUtilsHeader")
export const deleteAssignmentButtons = $('#deleteAllButtons')
export const createAssignmentButton = $('#createAssignment')
export const reCalcAssignmentButton = $('#reCalcAssignments')
export const filteredInventoryTableHeader = $("#filteredInventoryHeader")
export const filteredInventoryTable = $("#filteredInventoryTable")
export const filterStatusSelector = $("#filterstatusselector")
export const filterInventorySubmitButton = $("#filterInvbtn")
export const filterOperatorSelector = $("#filteroperatorselector")
export const filterAccountSelector = $("#filteraccountselector")
export const imOperatorSelector = $("#IMoperatorSelector")
export const imAccountSelector = $("#IMaccountSelector")
export const formOperatorSelector = $("#formOperatorSelector")
export const formAccountSelector = $("#formAccountSelector")
export const formRepTypeSelector = $("#formRepTypeSelector")
export let editMode = false
export let assignmentMode = false
export let apiTypes = null

export function enableFilterInventoryButton() {
    $("#filterInvbtn").prop('disabled', false)
}
export function disableFilterInventoryButton() {
    $("#filterInvbtn").prop('disabled', true)
}
export function showFilteredInventoryTable() {
    $("#filteredInventoryTable").prop('hidden',false)
}
export function showAssignmentManagementButtons() {
    $('#deleteAllButtons').prop('hidden', false)
    $('#createAssignment').prop('hidden', false)
    $('#reCalcAssignments').prop('hidden', false)
}
export function hideAssignManagementButtons() {
    $('#deleteAllButtons').prop('hidden', true)
    $('#createAssignment').prop('hidden', true)
    $('#reCalcAssignments').prop('hidden', true)
}
export function clearOtherInfoDiv() {
    $("#otherinfo").html('');
}
export function clearInfoDiv() {
    $('#infocontainer').html('')
}
export function hideAssignmentsButton(){
    $("#assignmentsbutton").prop('hidden',true);
}
export function showAssignmentsButton(){
    $("#assignmentsbutton").prop('hidden',false);
}
export function clearNextButton(){
    $("#nextbutton").html('');
}
export function clearPrevButton(){
    $("#prevbutton").html('');
}
export function clearCoordinateDisplay() {
    $("#coordinateDisplayDiv").html('')
}
export function showInventoryManagementSidebar() {
    $("#inventoryManagementInfo").prop('hidden',false)
}
export function hideInventoryManagementSidebar() {
    $("#inventoryManagementInfo").prop('hidden',true)
}
export function hideOtherInfo() {
    $("#otherinfo").prop('hidden',true)
}
export function showOtherInfo() {
    $("#otherinfo").prop('hidden',false)
}
export function hideToolSidebar(){
    $("#toolSideBar").prop('hidden',true)
}
export function showToolSidebar(){
    $("#toolSideBar").prop('hidden',false)
}
export function showIMTableLoading(){
    $("#imTableLoading").prop("hidden",false)
}
export function hideIMTableLoading(){
    $("#imTableLoading").prop("hidden",true)
}
export function hideAssignmentTable() {
    $("#assignmentTable").prop("hidden",true)
    $("#legacyassignmentTable").prop("hidden",true)
}
export function showAssignmentTable() {
    $("#assignmentTable").prop("hidden",false)
}
export function showLegacyAssignmentTable() {
    $("#legacyassignmentTable").prop("hidden",false)
}
export function clearAssignmentHeaderText() {
    $("#assignmentHeaderText").html('')
}
export function clearSubMapRow() {
    $("#submaprow").html('')
}
export function clearSubMapHeader() {
    $("#submaprowHeader").html('')
}
export function clearMapUtilsHeader() {
    $("#mapUtilsHeader").html('')
    $("#editorLayouts").html('')
}
export function clearMapUtilsBody() {
    $("#editBody").prop("hidden",true)
    $("#inventoryFrameRow").html('')
    $("#inventoryRecordRow").html('')
}
export function clearFilteredInfo(){
    $("#filteredInventoryHeaderHolder").prop("hidden",true)
    $("#filteredInventoryTable").prop("hidden",true)
    $("#frameIDFilter").prop("hidden",true)
}
export function showSubMapLoading() {
    $("#submapLoading").show()
}
export function hideSubMapLoading() {
    $("#submapLoading").hide()
}
export function showInfoLoading() {
    $("#infoLoading").show()
}
export function hideInfoLoading() {
    $("#infoLoading").hide()
}
export function changeAssignmentModeToTrue(){
    assignmentMode = true
}
export function setAssignmentModeToFalse() {
    assignmentMode = false
}
export function changeEditModeToTrue(){
    editMode = true
}
export function setEditModeToFalse() {
    editMode = false
}
export function showInsightsReference(){
    $("#insightsSideBar").attr('hidden',false)
}
export function hideInsightsReference(){
    $("#insightsSideBar").attr('hidden',true)
}
export function showMapSideBar(){
    $("#mapSideBar").attr('hidden',false)
}
export function hideMapSideBar(){
    $("#mapSideBar").attr('hidden',true)
}

export function clearRedundantFields (frameObj) {
    delete (frameObj['classification_type']['name']);
    delete (frameObj['classification_type']['description']);
    delete (frameObj['classification_type']['updated_desc']);
    delete (frameObj['classification_type']['created_by']);
    delete (frameObj['classification_type']['created_dtm']);
    delete (frameObj['classification_type']['updated_by']);
    delete (frameObj['classification_type']['updated_dtm']);
    delete (frameObj['classification_type']['current_ind']);
    delete (frameObj['construction']['construction_type']['name']);
    delete (frameObj['construction']['construction_type']['description']);
    delete (frameObj['construction']['construction_type']['updated_desc']);
    delete (frameObj['construction']['construction_type']['created_by']);
    delete (frameObj['construction']['construction_type']['created_dtm']);
    delete (frameObj['construction']['construction_type']['updated_by']);
    delete (frameObj['construction']['construction_type']['updated_dtm']);
    delete (frameObj['construction']['construction_type']['current_ind']);
    delete (frameObj['construction']['representations'][0]['representation_type']['name']);
    delete (frameObj['construction']['representations'][0]['representation_type']['description']);
    delete (frameObj['construction']['representations'][0]['representation_type']['updated_desc']);
    delete (frameObj['illumination_type']['name']);
    delete (frameObj['illumination_type']['description']);
    delete (frameObj['illumination_type']['updated_desc']);
    delete (frameObj['illumination_type']['created_by']);
    delete (frameObj['illumination_type']['created_dtm']);
    delete (frameObj['illumination_type']['updated_by']);
    delete (frameObj['illumination_type']['updated_dtm']);
    delete (frameObj['illumination_type']['current_ind']);
    delete (frameObj['media_type']['name']);
    delete (frameObj['media_type']['description']);
    delete (frameObj['media_type']['updated_desc']);
    delete (frameObj['media_type']['created_by']);
    delete (frameObj['media_type']['created_dtm']);
    delete (frameObj['media_type']['updated_by']);
    delete (frameObj['media_type']['updated_dtm']);
    delete (frameObj['media_type']['current_ind']);
    if (frameObj['placement_type']) {
        delete (frameObj['placement_type']['name']);
        delete (frameObj['placement_type']['description']);
        delete (frameObj['placement_type']['updated_desc']);
        delete (frameObj['placement_type']['created_by']);
        delete (frameObj['placement_type']['created_dtm']);
        delete (frameObj['placement_type']['updated_by']);
        delete (frameObj['placement_type']['updated_dtm']);
        delete (frameObj['placement_type']['current_ind']);
    }
    delete (frameObj['status_type']['name']);
    delete (frameObj['status_type']['description']);
    delete (frameObj['status_type']['updated_desc']);
    delete (frameObj['status_type']['created_by']);
    delete (frameObj['status_type']['created_dtm']);
    delete (frameObj['status_type']['updated_by']);
    delete (frameObj['status_type']['updated_dtm']);
    delete (frameObj['status_type']['current_ind']);
    delete (frameObj['representations'][0]['representation_type']['name']);
    delete (frameObj['representations'][0]['representation_type']['description']);
    delete (frameObj['representations'][0]['representation_type']['updated_desc']);
    delete (frameObj['representations'][0]['representation_type']['created_by']);
    delete (frameObj['representations'][0]['representation_type']['created_dtm']);
    delete (frameObj['representations'][0]['representation_type']['updated_by']);
    delete (frameObj['representations'][0]['representation_type']['updated_dtm']);
    delete (frameObj['representations'][0]['representation_type']['current_ind']);
    delete (frameObj['layouts'][0]['representations'][0]['representation_type']['name']);
    delete (frameObj['layouts'][0]['representations'][0]['representation_type']['description']);
    delete (frameObj['layouts'][0]['representations'][0]['representation_type']['updated_desc']);
    delete (frameObj['layouts'][0]['faces'][0]['representations'][0]['representation_type']['name']);
    delete (frameObj['layouts'][0]['faces'][0]['representations'][0]['representation_type']['description']);
    delete (frameObj['layouts'][0]['faces'][0]['representations'][0]['representation_type']['updated_desc']);
    $.each(frameObj['layouts'][0]['faces'][0]['spots'], function (index, value) {
        delete (value['measures']);
        delete (value['representations'][0]['representation_type']['name']);
        delete (value['representations'][0]['representation_type']['description']);
        delete (value['representations'][0]['representation_type']['updated_desc']);
    });
    return frameObj
}

export class StringIdGenerator {
    constructor(chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ') {
        this._chars = chars;
        this._nextId = [0];
    }

    next() {
        const r = [];
        for (const char of this._nextId) {
            r.unshift(this._chars[char]);
        }
        this._increment();
        return r.join('');
    }

    _increment() {
        for (let i = 0; i < this._nextId.length; i++) {
            const val = ++this._nextId[i];
            if (val >= this._chars.length) {
                this._nextId[i] = 0;
            } else {
                return;
            }
        }
        this._nextId.push(0);
    }

    *[Symbol.iterator]() {
        while (true) {
            yield this.next();
        }
    }
}


