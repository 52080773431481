import {apiTypes} from "../supportFunctions/consts";

export function mediaParse(mediaData) {
    let list = {"":""};
    mediaData.sort();
    mediaData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return {values:list}
}
export function tfParams() {
 return {values:{"true":"true","false":"false"}}
}

export function classificationParamBuilder(){
    return mediaParse(this.getData().map(a => a.classification_type_name))
}
export function statusParamBuilder(){
    return mediaParse(this.getData().map(a => a.status_type_name))
}
export function mediaParamBuilder(){
    return mediaParse(this.getData().map(a => a.media_type_name))
}
export function illTypeParamBuilder(){
    return mediaParse(this.getData().map(a => a.illumination_type_name))
}
export function constructionParamBuilder(){
    return mediaParse(this.getData().map(a => a.construction_type_name))
}
export function framemediaParamBuilder(){
    return mediaParse(this.getData().map(a => a.frame_media_name))
}
export function accountParamBuilder(){
    return mediaParse(this.getData().map(a => a.account_name))
}
export function typeEditorOptions() {
    $.each(apiTypes)
}
export function networkYearBuilder() {
    return mediaParse(this.getData().map(a => a.network_year))
}
export function streetNameBuilder() {
    return mediaParse(this.getData().map(a => a.st_name))
}
export function modeBuilder() {
    return mediaParse(this.getData().map(a => a.mode))
}
export function roadClassBuilder() {
    return mediaParse(this.getData().map(a => a.func_class))
}
export function directionBuilder() {
    return mediaParse(this.getData().map(a => a.seg_dir))
}
