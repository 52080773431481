import $ from "jquery";
import {filterAccountSelector, filterOperatorSelector} from "./consts";
import {getInventoryManagementList} from "../inventoryManagement/inventoryManagementHandler";

export let accounts
export let accounts_under_review
export let parent_accounts

export async function setaccountSelectors(accounts, selector, parentSelector) {
    let account_selected_list = []
    let accountpromises = []
    selector.html('').select2({
        theme: 'bootstrap4',
        placeholder: "Select Account",
        data: [{
            id: '',
            text: ''
        }]
    })
    let selectedParentAccount = parentSelector.select2('data')
    $.each(accounts, function (index, value) {
        accountpromises.push(new Promise(function (resolve) {
            if (value['parent_account_id'] === parseInt(selectedParentAccount[0]['id'])) {
                account_selected_list.push({"id": value['id'], "text": value['text']})
            }
            resolve()
        }))
    })
    await Promise.all(accountpromises).then(function () {
        if (account_selected_list.length === 0) {
            account_selected_list.push({"id": selectedParentAccount[0]['id'], "text": 'SAME AS PARENT'})
        }
        selector.select2({
            theme: 'bootstrap4',
            placeholder: "Select Account",
            data: account_selected_list
        })
    })
}

export function getAccounts() {
    let parentpromises = []
    parent_accounts = []
    accounts = []
    let settings = {
        "url": process.env.appurl + "/api/getAccounts",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        }
    }
    $.ajax(settings).done(async function (response) {
        $.each(response['data']['operators'], function (index, value) {
            parentpromises.push(new Promise(function (resolve) {
                if (!value['parent_account_id']) {
                    parent_accounts.push({"id": value['id'], "text": value['name']})
                } else if (value['parent_account_id'] === value['id']) {
                    parent_accounts.push({"id": value['id'], "text": value['name']})
                } else {
                    accounts.push({
                        "id": value['id'],
                        "text": value['name'],
                        "parent_account_id": value['parent_account_id']
                    })
                }
                resolve()
            }))
        })
        await Promise.all(parentpromises).then(function () {
            $("#filteroperatorselector").select2({
                placeholder: "Select Operator",
                data: parent_accounts
            })
            $("#IMoperatorSelector").select2({
                placeholder: "Select Operator",
                data: parent_accounts
            })
            $("#formOperatorSelector").select2({
                placeholder: "Select Operator",
                data: parent_accounts
            })
            $("#referenceoperatorselector").select2({
                placeholder: "Select Operator",
                data: parent_accounts
            })
            $("#createAccountToolParentDropDown").select2({
                theme: 'bootstrap4',
                placeholder: "Select Parent Account",
                data: parent_accounts
            })
            $("#createRepParentselector").select2({
                theme: 'bootstrap4',
                placeholder: "Select Parent Account",
                data: parent_accounts
            })
        })

    })
}

export function selectorActions() {
    filterOperatorSelector.on('select2:select', function () {
        setaccountSelectors(accounts, filterAccountSelector, filterOperatorSelector)
    })
    $("#IMoperatorSelector").on('select2:select', function () {
        setaccountSelectors(accounts, $("#IMaccountSelector"), $("#IMoperatorSelector"))
    })
    $("#referenceoperatorselector").on('select2:select', function () {
        setaccountSelectors(accounts, $("#referenceaccountselector"), $("#referenceoperatorselector"))
        $("#referenceOperatorID").innerHTML = ('')
        let data = $("#referenceoperatorselector").select2('data')
        // console.log(data)
        $("#referenceOperatorID").html(
            '<br>' +"Parent Account ID = " + '<span><b>' + data[0]['id'] + '</b></span><br>Account ID = <b><span id="referenceaccountidspan"></span></b> '
        )
    })
    $("#createRepParentselector").on('select2:select', function () {
        setaccountSelectors(accounts, $("#createRepAccountSelector"), $("#createRepParentselector"))
        // $("#referenceOperatorID").innerHTML = ('')
        let data = $("#createRepParentselector").select2('data')
        // console.log(data)
        // $("#referenceOperatorID").html(
        //     '<br>' +"Parent Account ID = " + '<span><b>' + data[0]['id'] + '</b></span><br>Account ID = <b><span id="referenceaccountidspan"></span></b> '
        // )
    })
    $("#referenceaccountselector").on('select2:select', function () {
        let data = $("#referenceaccountselector").select2('data')
        // console.log(data)
        $("#referenceaccountidspan").text(data[0]['id'])
    })
    $("#formOperatorSelector").on('select2:select', function () {
        $("#formRepTypeSelector").html('').select2({
            placeholder: "Select Rep Type",
            data: [{
                id: '',
                text: ''
            }]
        })
        setaccountSelectors(accounts, $("#formAccountSelector"), $("#formOperatorSelector"))
    })

    $("#IMaccountSelector").on('select2:select', function () {
        getInventoryManagementList($("#IMaccountSelector").select2('data'))
    })
    $("#formAccountSelector").on('select2:select', function () {
        getDBreps($("#formAccountSelector").select2('data'))
    })

}

export function getDBreps(data,selector) {
    selector.html('').select2({
        placeholder: "Select Rep Type",
        data: [{
            id: '',
            text: ''
        }]
    })
    let account_id = data[0]['id']
    let settings = {
        "url": process.env.appurl + "/api/getDBreps",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "account_id": account_id
        }
    }
    $.ajax(settings).done(function (response) {
        let responsedata = []
        $.each(response, function (index, value) {
            responsedata.push({id: value['representation_id'], text: value['rep_type_name']})
        })
        selector.select2({
            theme: 'bootstrap4',
            placeholder: "Select Rep Type",
            data: responsedata
        })
    })
}

export async function getAppealedAccounts() {
    accounts_under_review = []
    let accessToken = "Bearer " + localStorage.getItem('access_token');
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/accountsUnderAppeal',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": accessToken
        }
    };
    $.ajax(settings).done(function (response) {
        accounts_under_review = response
    });
}

export function checkAccountForAppeal(account_id) {
    let accountList = accounts_under_review
    // console.log(accountList.some(item => item.account_id === account_id))
    return accountList.some(item => item.account_id === account_id)
}
