import select2 from "select2";
import $ from "jquery";
import createAuth0Client from '@auth0/auth0-spa-js';
// import {prepEasterEgg} from "../supportFunctions/easteregg.js";
import {getAccounts, getAppealedAccounts, selectorActions} from "../supportFunctions/accounts.js";
import {getAPITypes} from "../supportFunctions/apiTypes.js";

window.addEventListener('load', async function () {
    // set items for use later and block out window until authenticated
    select2($);
    const content = document.querySelector('.container-fluid');
    content.style.display = 'none';
    const loginButton = document.getElementById("btn-login");
    const logoutButton = document.getElementById("btn-logout");
    loginButton.style.display = 'inline-block';
    logoutButton.style.display = 'none';

    //function to set storage items and kick off api calls once authenticated
    const setSession = function (userProfile, claims, token) {
        localStorage.setItem('access_token', token);
        localStorage.setItem('id_token', claims['__raw']);
        localStorage.setItem('userID', userId);
        localStorage.setItem('companyName', companyName);
        localStorage.setItem('userEmail', userEmail);
        content.style.display = 'block';
        // prepEasterEgg()
        getAccounts()
        getAppealedAccounts()
        getAPITypes()
        selectorActions()
    }

    // create auth0 client
    createAuth0Client({
        domain: "geopath.auth0.com",
        client_id: "Nek3qM4qnjv5wdXqSSySejZk6f27571i",
        redirect_uri: window.location.origin,
        audience: 'https://atlas.geopath.org/api',
        scope: 'openid profile email',
        useRefreshTokens: true
    }).then(async (auth0) => {
        let token

        //add listener to login
        loginButton.addEventListener("click", (e) => {
            e.preventDefault();
            auth0.loginWithRedirect();
            loginButton.style.display = 'none';
            logoutButton.style.display = 'inline-block';
        });
        //// for use with auth0 popup; some privacy focused browsers may need this step
        // const token = await auth0.getTokenWithPopup({
        //     audience: 'https://atlas.geopath.org/api',
        //     scope: 'openid profile email',
        //     useRefreshTokens: true
        // });

        // check if user is authenticated recently and set token else login via redirect screen
        try {
            token = await auth0.getTokenSilently({
                audience: 'https://atlas.geopath.org/api',
                scope: 'openid profile email',
                useRefreshTokens: true
            })
        } catch (e) {
            if (e.error === 'login_required') {
                auth0.loginWithRedirect();
            }
            if (e.error === 'consent_required') {
                auth0.loginWithRedirect();
            }
            throw e;
        }

        // after redirect handle the callback to the app
        if (location.search.includes("state=") &&
            (location.search.includes("code=") ||
                location.search.includes("error="))) {
            await auth0.handleRedirectCallback();
            window.history.replaceState({}, document.title, "/");
        }

        //add logout button listener and remove local items when logged out
        logoutButton.addEventListener("click", (e) => {
            e.preventDefault();
            localStorage.removeItem('access_token');
            localStorage.removeItem('id_token');
            localStorage.removeItem('userID');
            localStorage.removeItem('companyName');
            localStorage.removeItem('userEmail');
            auth0.logout({
                returnTo: process.env.appurl
            });
            loginButton.style.display = 'inline-block';
            logoutButton.style.display = 'none';
            content.style.display = 'none';
        });

        //get data from auth0
        const isAuthenticated = await auth0.isAuthenticated();
        const userProfile = await auth0.getUser();
        const claims = await auth0.getIdTokenClaims();

        //check is user is authenticated and check company to determine if setSession is called, else fail gracefully
        if (isAuthenticated) {
            window.userEmail = userProfile["email"];
            window.companyName = userProfile["https://comparison.geopath.org/app"]['company']['name'];
            window.userId = userProfile['sub']
            if (companyName === 'Geopath') {
                if (userProfile && token && claims) {
                    setSession(userProfile, claims, token);
                    loginButton.style.display = 'none';
                    logoutButton.style.display = 'inline-block';

                }
            } else if (companyName === 'Intermx') {
                if (userProfile && token && claims) {
                    setSession(userProfile, claims, token);
                    loginButton.style.display = 'none';
                    logoutButton.style.display = 'inline-block';
                }
            } else {
                alert('Sorry You Do not have access to this tool');
                content.innerHTML = '<br><br><br><br><h2>Sorry You Do not have access to this tool</h2>';
                content.style.display = 'inline-block';
                loginButton.style.display = 'inline-block';
                logoutButton.style.display = 'none';
            }
        } else {
            alert('Login Failed, Please double check your credentials and try again..');
        }
    });
});