import $ from "jquery";

export let classificationTypeVals = {values: []}
export let constructionTypeVals = {values: []}
export let mediaTypeVals = {values: []}
export let frameMediaTypeVals = {values: []}
export let statusTypeVals = {values: []}
export let placeTypeVals = {values: []}
export let placementTypeVals = {values: []}
export let illuminationTypeVals = {values: []}
export let classificationSelectVals = []
export let constructionSelectVals = []
export let mediaSelectVals = []
export let frameMediaSelectVals = []
export let statusSelectVals = []
export let placeTypeSelectVals = []
export let placementSelectVals = []
export let illuminationSelectVals = []
export let representationTypeVals = {values: []}
export let representationTypeSelectVals =[]

export function getAPITypes() {
    let accessToken = "Bearer " + localStorage.getItem('access_token');
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getAPITypes',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": accessToken
        }
    };
    $.ajax(settings).done(function (response) {
        $.each(response, function (index, val) {
            if (val['type'] === 'classification_type') {
                $.each(val['data'], function (index, valu) {
                    classificationTypeVals.values.push(
                        {label: valu['classification_type_name'], value: valu['classification_type_id']}
                    )
                    classificationSelectVals.push(
                        {text: valu['classification_type_name'], id: valu['classification_type_id']}
                    )
                })
            } else if (val['type'] === 'media_type') {
                $.each(val['data'], function (index, valu) {
                    mediaTypeVals.values.push(
                        {label: valu['media_type_name'], value: valu['media_type_id']}
                    )
                    mediaSelectVals.push(
                        {text: valu['media_type_name'], id: valu['media_type_id']}
                    )
                })
            } else if (val['type'] === 'frame_media') {
                $.each(val['data'], function (index, valu) {
                    frameMediaTypeVals.values.push(
                        {label: valu['media_type_name'], value: valu['media_type_id']}
                    )
                    frameMediaSelectVals.push(
                        {text: valu['media_type_name'], id: valu['media_type_id']}
                    )
                })
            } else if (val['type'] === 'illumination_type') {
                $.each(val['data'], function (index, valu) {
                    illuminationTypeVals.values.push(
                        {label: valu['illumination_type_name'], value: valu['illumination_type_id']}
                    )
                    illuminationSelectVals.push(
                        {text: valu['illumination_type_name'], id: valu['illumination_type_id']}
                    )
                })
            } else if (val['type'] === 'place_type') {
                $.each(val['data'], function (index, valu) {
                    placeTypeVals.values.push(
                        {label: valu['place_type_name'], value: valu['place_type_id']}
                    )
                    placeTypeSelectVals.push(
                        {text: valu['place_type_name'], id: valu['place_type_id']}
                    )
                })
            } else if (val['type'] === 'placement_type') {
                $.each(val['data'], function (index, valu) {
                    placementTypeVals.values.push(
                        {label: valu['placement_type_name'], value: valu['placement_type_id']}
                    )
                    placementSelectVals.push(
                        {text: valu['placement_type_name'], id: valu['placement_type_id']}
                    )
                })
            } else if (val['type'] === 'status_type') {
                $.each(val['data'], function (index, valu) {
                    statusTypeVals.values.push(
                        {label: valu['status_type_name'], value: valu['status_type_id']}
                    )
                    statusSelectVals.push(
                        {text: valu['status_type_name'], id: valu['status_type_id']}
                    )
                })
            } else if (val['type'] === 'representation_type') {
                $.each(val['data'], function (index, valu) {
                    representationTypeVals.values.push(
                        {label: valu['rep_type_name'], value: valu['rep_type_id']}
                    )
                    representationTypeSelectVals.push(
                        {text: valu['rep_type_name'], id: valu['rep_type_id']}
                    )
                })
            } else if (val['type'] === 'construction_type') {
                $.each(val['data'], function (index, valu) {
                    constructionTypeVals.values.push(
                        {label: valu['construction_type_name'], value: valu['construction_type_id']}
                    )
                    constructionSelectVals.push(
                        {text: valu['construction_type_name'], id: valu['construction_type_id']}
                    )
                })
            }
        })
    })
}